<template>
  <div>
    <v-row class="pa-4">
      <v-col cols="6">
        <h1 :class="coreOnline ? 'green--text' : 'red--text'">Core: {{ coreOnline ? 'Online' : 'Offline' }}</h1>
        <h2 :class="moneyOnline ? 'green--text' : 'red--text'">Money: {{ moneyOnline ? 'Online' : 'Offline' }}</h2>
        <h2 :class="cardOnline ? 'green--text' : 'red--text'">Card: {{ cardOnline ? 'Online' : 'Offline' }}</h2>
        <h2 :class="printOnline ? 'green--text' : 'red--text'">Print: {{ printOnline ? 'Online' : 'Offline' }}</h2>
        <v-row class="pt-4 d-flex flex-column">
          <h3>Events</h3>
          <vue-json-pretty v-for="(event, i) in events" v-bind:key="i" :data="event"
                           :options="{ theme: 'dracula' }"></vue-json-pretty>

        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row class="text-center">
          <v-col cols="4">
            <v-btn class="cyan lsn" @click="getStatus('bill')">Get bill status</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn class="cyan lsn" @click="getStatus('coin')">Get coin status</v-btn>
          </v-col>
          <v-col cols="4">
            <v-btn class="cyan lsn" @click="getPeripherals">Get peripherals</v-btn>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="3">
            <v-btn class="light-green lsn" @click="toggleAcceptor('bill', 'enable')">Enable bill</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn class="red lsn" @click="toggleAcceptor('bill', 'disable')">Disable bill</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn class="silver lsn" @click="toggleAcceptor('coin', 'enable')">Enable coin</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn class="red lsn" @click="toggleAcceptor('coin', 'disable')">Disable coin</v-btn>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="3">
            <v-btn class="light-green lsn" @click="acceptMoney(true)">Enable all acceptors</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn class="red lsn" @click="acceptMoney(false)">Disable all acceptors</v-btn>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-col cols="3">
            <v-btn class="cyan lsn" @click="getPrinterServiceInfo">Get printer service info</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn class="cyan lsn" @click="getPrinters">Get printers</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn class="cyan lsn" @click="getPrinterIndex">Printer Index</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn class="cyan lsn" @click="getPrinterStatus">Printer status</v-btn>
          </v-col>
        </v-row>
        <v-row class="text-center">
          <v-btn class="cyan lsn" @click="runExamplePrint">Run example print</v-btn>
        </v-row>
        <v-row class="pt-4 d-flex flex-column">
          <h3>Response</h3>
          <vue-json-pretty :data="currentResponse" :options="{ theme: 'dracula' }"></vue-json-pretty>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {Core, Money, Card, Print, Fetcher } from "@nsftx/systems-sdk";
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css';

export default {
  components: {VueJsonPretty},
  data: () => ({
    coreOnline: false,
    moneyOnline: false,
    cardOnline: false,
    printOnline: false,
    fetcherOnline: false,
    core: {},
    events: [],
    money: {},
    card: {},
    fetcher: {}, 
    currentResponse: null,
  }),
  methods: {
    getPeripherals() {
      this.money.getPeripheralsInfo().then(response => {
        console.log(response);
        this.currentResponse = JSON.stringify(response);
      });
    },
    getStatus(acceptor) {
      console.log(acceptor)
      this.money.getAcceptorStatus(acceptor).then(response => {
        console.log(response)
        this.currentResponse = JSON.stringify(response);
      })
    },
    toggleAcceptor(acceptor, status) {
      this.money.toggleAcceptorStatus(acceptor, status);
      this.currentResponse = ""
    },
    acceptMoney(bool) {
      this.money.acceptMoney(bool);
    },
    getPrinterServiceInfo() {
      this.print.getServiceInfo().then(response => {
        this.currentResponse = JSON.stringify(response);
      })
    },
    getPrinters() {
      this.print.getPrinters().then(response => {
        this.currentResponse = JSON.stringify(response);
      });
    },
    getPrinterIndex() {
      this.print.index().then(response => {
        this.currentResponse = JSON.stringify(response);
      });
    },
    getPrinterStatus() {
      this.print.getStatus().then(response => {
        this.currentResponse = JSON.stringify(response);
      })
    },
    runExamplePrint() {
      this.print.index().then(response => {
        let currentPrinterModel = response.printers[0].printer.model
        let printerIndex = response.printers[0].printer.id;
        let template = `
        <html data-margin="0" data-line-spacing="5" data-width-reduction="0">
<head>
    title=LiveBetting
</head>
<body>
<div class="row center">
    <logo/>
</div>
<div class="row">

    <p class="left">
      <span>Betshop: marino, kneza domagoja</span>
    </p>


    <p class="left">
      <span>Device: </span>
    </p>


    <p class="left">
      <span>Issuer: vedo</span>
    </p>

</div>
<div class="row">
  <br/>
</div><div class="row">

  <h1 class="center bold">Live Betting</h1>

</div>

<div class="row"><br /></div>
<div class="row"><hr class="equals"/></div>
<div class="row">
  <div class="col">
    <p class="left">
      <span class="bold">Issued</span>
    </p>
  </div>
  <div class="col">
    <p class="right">
      <span class="bold">10.10. 13:36:32</span>
    </p>
  </div>
</div>
<div class="row">
  <div class="col">
    <p class="left">
      <span class="bold">Ticket ID</span>
    </p>
  </div>
  <div class="col">
    <p class="right">
      <span class="bold">X117V29FD</span>
    </p>
  </div>
</div>
<div class="row"><hr class="equals"/></div>
<div class="row"><br /></div>
<div class="row"><hr class="dash"/></div>
<div class="row">
  <p class="center">


        <span class="bold">Combo</span>


  </p>
</div>
<div class="row"><hr class="dash"/></div>


  <div class="row">
    <div class="col" data-width="1">
      <p class="left">

        <span></span>

      </p>
    </div>
    <div class="col" data-width="6">
      <p class="right">

        <span class="bold">610 </span>

      </p>
    </div>
    <div class="col">
      <p class="left">

        <span class="bold">Trinker, Jonas - Parizzia, Nicolas</span>

      </p>
    </div>
  </div>
  <div class="row">
    <div class="col" data-width="7">
      <p class="left">
        <span></span>
      </p>
    </div>
    <div class="col">
      <p class="left">

        <span>Who will win first [Z] points of
\t\t\tgame [Y] of set [X]</span>


        <span> (3|5|2)</span>

      </p>
    </div>
  </div>
  <div class="row">
    <div class="col" data-width="7">
      <p class="left">
      <span></span>
      </p>
    </div>
    <div class="col" data-width="22">
      <p class="left">
        <span>Pick: </span><span>x</span>


      </p>
    </div>
    <div class="col">
      <p class="right">

        <span>1.92</span>

      </p>
    </div>
  </div>


  <div class="row"><hr class="dash"/></div>



  <div class="row">
    <div class="col" data-width="1">
      <p class="left">

        <span></span>

      </p>
    </div>
    <div class="col" data-width="6">
      <p class="right">

        <span class="bold">816 </span>

      </p>
    </div>
    <div class="col">
      <p class="left">

        <span class="bold">Saccani, Silvia - Lanca, Sara</span>

      </p>
    </div>
  </div>
  <div class="row">
    <div class="col" data-width="7">
      <p class="left">
        <span></span>
      </p>
    </div>
    <div class="col">
      <p>

        <span>Which player will win the match?</span>


      </p>
    </div>
  </div>
  <div class="row">
    <div class="col" data-width="7">
      <p class="left">
      <span></span>
      </p>
    </div>
    <div class="col" data-width="29">
      <p class="left">
        <span>Pick: </span>


            <span>Saccani, Silvia </span>


      </p>
    </div>
    <div class="col">
      <p class="right">

        <span>1.33</span>

      </p>
    </div>
  </div>


  <div class="row"><hr class="dash"/></div>



<div class="row"><br /></div>
<div class="row">
  <div class="col">
    <p class="left">
      <span class="bold">Total odds:</span>
    </p>
  </div>
  <div class="col">
    <p class="right">

      <span class="bold">2.55</span>

    </p>
  </div>
</div>
<div class="row"><br /></div>


<div class="row"><hr class="equals"/></div>
<div class="row">
  <div class="col">
    <p class="left">
      <span class="bold">Stake:</span>
    </p>
  </div>
  <div class="col">
    <p class="right">
      <span class="bold">1,00 BAM</span>
    </p>
  </div>
</div>


<div class="row"><hr class="dash" /></div>
<div class="row">
  <div class="col">
    <p class="left">
      <span class="bold">Max winnings:</span>
    </p>
  </div>
  <div class="col">
    <p class="right">
      <span class="bold">2,55 BAM</span>
    </p>
  </div>
</div>

<div class="row">
\t<hr class="equals"/>
</div>
<div class="row">
  <br/>
</div>

  <div class="row center">
    <barcode value="X117V29FD"></barcode>
  </div>
</html>`

        this.print.addPrintJob(template, currentPrinterModel, printerIndex).then(response => {
          console.log(response);
        });
      })
    }
  },
  mounted() {
    this.sys = new Core({url: "http://localhost:5000/centralHub"});
    this.sys.connect();
    let data = this.sys
    console.log(data)
    this.sys.on('connection', () => {
      this.coreOnline = true;
      this.money = new Money(this.sys);
      this.money.on('ready', () => {
        this.moneyOnline = true;
      });
      this.money.on('moneyProcessed', (data) => {
        this.events.push(JSON.stringify(data));
      });
      this.money.on('moneyUnprocessed', (data) => {
        this.events.push(JSON.stringify(data));
      });
      this.money.on('moneyProcessing', (data) => {
        this.events.push(JSON.stringify(data));
      });

      this.card = new Card(this.sys);
      this.card.on('ready', () => {
        this.cardOnline = true;
      });
      this.card.on('cardIn', (data) => {
        console.log(data);
      });
      this.card.on('cardOut', (data) => {
        console.log(data);
      });
      this.card.on('cardRead', (data) => {
        this.events.push(data)
      })

      this.print = new Print(this.sys);
      this.print.on('ready', () => {
        this.printOnline = true;
      });
      this.fetcher = new Fetcher(this.sys);
      this.fetcher.on('ready', () => {
        this.fetcherOnline = true;
      });
    })
  }
}
</script>

<style scoped>

</style>
